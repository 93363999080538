@import "../../scss/Player.scss";

.playlist-player-container.player-container {
    height: 100vh;
    
    .player-header {
        height: 68px;
    }

    .main__wrapper {
        position: relative; height: 100%;

        > main {
            // 117 = 68 px header + 49px footer
            height: calc(100vh - 117px);
            display: flex;
            flex-direction: column-reverse; /* 'column' for start, 'column-reverse' for end */
            overflow-y: scroll;

            // You'll break the chatbot greyed background if you add position here
        }
    }
}
