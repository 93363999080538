@import "../../scss/App.scss";

.student-progress-bar {
    width: 100%;
    height: 100%;
    position: relative;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
        border-radius: 50%;
        border: 2px solid #fff;
        text-align: center;
        height: 32px;
        width: 32px;
        margin: 0 8px;
        display: block;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 24px;
            color: #fff;
        }

        &:not(:last-child)::after {
            content: "";
            width: 20px;
            height: 3px;
            background-color: white;
            position: absolute;
            top: 14px;
            right: -20px;
        }

        &.progress-bar__item--correct {
            background-color: $colorCorrect;
        }

        &.progress-bar__item--incorrect {
            background-color: $colorError;
        }

        &.progress-bar__item--solving {
            background-color: white;
        }

        &.progress-bar__item--unsolved,
        &.progress-bar__item--skipped {
            background-color: $undefined;
        }

        img {
            height: 24px;
            width: 24px;
        }
    }
}

@media screen and (max-width: 767px) {
    .student-progress-bar {
        padding: 0 8px;
        position: relative;
        justify-content: flex-start;

        li {
            width: 24px;
            height: 24px;
            margin: 4px;

            span {
                font-size: 16px;
            }

            &:not(:last-child)::after {
                width: 10px;
                top: 10px;
                right: -10px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
